import { Component, ElementRef, EventEmitter, forwardRef, HostListener, Input, OnInit, Output, QueryList, SimpleChanges, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgbDropdown, NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { SimpleTimer } from 'ng2-simple-timer';
import { BehaviorSubject, fromEvent, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { LocalCacheService } from '../../../../core/local-cache/local-cache.service';
import { DropdownSearchPipe } from '../../../../core/pipes/drop-down-search-pipe/drop-down-search.pipe';
const KEY_CODE = {
  enter: 13,
  arrowUp: 38,
  arrowDown: 40,
  esc: 27,
};

const CSS_CLASS_NAMES = {
  highLight: 'dd-highlight-item',
};
@Component({
  selector: 'nalco-shared-dropdown',
  templateUrl: './shared-dropdown.component.html',
  styleUrls: ['./shared-dropdown.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    NgbDropdownConfig,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SharedDropdownComponent),
      multi: true
    }
  ]
})
export class SharedDropdownComponent implements OnInit {
  @ViewChild(NgbDropdown, { static: false })
  private dropdown: NgbDropdown;
  @ViewChild('filterInput', { static: false }) filterInput: ElementRef;
  @ViewChild('displayLabel', { static: false }) displayLabel: ElementRef;
  @ViewChildren('listItems') listItems: QueryList<ElementRef>;
  @ViewChild('checkBox', { static: false }) checkBox: ElementRef;
  @ViewChild('scroll', { static: false }) public scroll: ElementRef;
  _items = [];
  selectedItems: Array<{ ContextPointId: number, ContextPointName: string, checked: boolean, category: string, disable?: boolean }>;
  _list = new BehaviorSubject<any[]>([]);
  @Input() placeholder = this.translate.instant('COMMON.SELECT');
  @Input() SearchType?: string = 'list';
  @Output() itemSelected: EventEmitter<any> = new EventEmitter(); // Emits the toggle event of the button
  @Output() categorySelected: EventEmitter<any> = new EventEmitter();
  @Output() selectedItemList: EventEmitter<any> = new EventEmitter();
  @Output() lazyLoading: EventEmitter<any> = new EventEmitter();
  @Output() filterText: EventEmitter<any> = new EventEmitter();
  @Output() getSelectedItems: EventEmitter<any> = new EventEmitter();
  @Output() itemUnchecked?: EventEmitter<any> = new EventEmitter();
  @Output() toggleEvent?: EventEmitter<any> = new EventEmitter();
  @Output() unSelectAll?: EventEmitter<any> = new EventEmitter();
  @Input() isDisable?: boolean = false;
  @Input() displayOnInit = [];
  @Input() noRecoredsLabel = this.translate.instant('COMMON.NO_RECORDS_FOUND');
  @Input() noMatchingRecoredsLabel = this.translate.instant('COMMON.NO_RECORDS_FOUND');
  @Input() noMatchingCategory = this.translate.instant('COMMON.NO_RECORDS_FOUND');
  @Input() showLoader?: boolean = false;
  @Input() multiSelection?: boolean = false;
  @Input() categoryList;
  @Input() depValue: boolean = false;
  @Input() dependentValue: boolean = false;
  @Input() showUnmapped?: boolean = true;
  @Input() viewFavorite: boolean = false;
  @Input() showSearchBox?: boolean = true;
  @Input() showFilterBycategory?: boolean = true;
  @Input() checkedItemsLimit?: number = 4;
  @Input() showClearSelectionFlag?: boolean = true;
  @Input() addEditMode?: string;
  @Input() isSelectAllAvailable?: boolean;
  @Input() isSelectAllSelected?: boolean;
  @Input() serviceEnabledName?: string = '';
  @Input() multiselectText?: string = 'Select All';
  @Input() virtualMeterFlag?: boolean = false;
  @Input() disableDd?: boolean = false;
  @Input() isAHInventory?: boolean = false;
  @Input() isWaterUsage?: boolean = false;
  @Input() isAssetDetails?: boolean = false;
  @Input() isOmniSurveysTab?: boolean = false;
  @Input() headerValue?: string;
  @Input() scrolltoTop?: boolean = true;
  @Input() isfromMyDocuments?: boolean;
  @Input() showSelectionWithLabel? = '';
  @Input() disabled: boolean = false;
  @Input() dropDownSearchPlaceholder?: string = '';
  @Input() forManageSampleSet? = false;

  /**
   *** Code Explaination Started ***

   * @description Below code snippet is for input decorators, used for form validation

   * To apply form validation on shared dropdown, apply the form validation in form group and use the same name for [componentId] in parent HTML file
      eg. If dropdown name in form group is "classification" then pass same in [componentId]="'classification'"

      Along with that, declare an object in parent TS file and assign it to an variable for handling dropdown open/close status, structure is explained in syntax/example
      syntax: <variable_name> = {"<componentId>": false}
      eg. dropdownStatus = {"classification": false}

      Note: Assign false if you want to keep the dropdown closed on initial page load, also multiple componentId can be passed in the object comma seperated

      Once dropdown status handling object is declared, assign it to [dropdownStatus]="dropdownStatus" in parent HTML file

      Finally in parent TS file, you can declare a function for capturing emitted data from shared dropdown and assign it to (toggleEvent) in parent HTML file
      eg. In TS: getDropdownStatus(data){}
          In HTML <nalco-shared-dropdown [componentId]="'classification'" [dropdownStatus]="dropdownStatus" (toggleEvent)="getDropdownStatus($event)"></nalco-shared-dropdown>


      Refer "Create-Analysis" component for better understanding of the functionality
   */

  @Input() componentId: string;
  @Input() dropdownStatus?: object

  /**
   *** Code Explaination Ended ***
   */

  multiselectedItems = [];
  selectionLimitOverMsg: boolean = false;
  disableRecordsFlag: boolean = false;
  errorMsgTimer: any;
  categoryOptions: Array<any> = [];
  selectedCount: number = 0;
  _value: string;
  _display: string = 'T';
  isListHide: boolean = true;
  splicedItems = [];
  searchText: string = '';
  selectedCategory: string = '';
  newTimer: any;
  timerId: any;
  counter: number = 0;
  disableClearSelection: boolean = true;
  allOption: boolean = true;
  keyDowns: Observable<KeyboardEvent> = fromEvent(this.elemRef.nativeElement, 'keydown');
  pressEnterKey: Observable<KeyboardEvent>;
  @HostListener('document:click', ['$event'])
  onClick(ev: MouseEvent) {

    /**
     * Below code is for disabling the document click event inside the dropdown container
     */
    const clickedInsideComponentOne = this.elemRef.nativeElement.contains(ev.target);
    if (clickedInsideComponentOne) {
      ev.stopPropagation();
      return;
    }

    // this.sendValidatorStatus()
    this.onDocClickValidation()
    const clickInside = this.elemRef.nativeElement.contains(ev.target);
    if (!clickInside) {
      this.multiSelection ? this.toggle() : '';
      this.isListHide = true;
      if (this.scrolltoTop === undefined || this.scrolltoTop === true) {
        this.scroll.nativeElement.scrollTop = 0;
      }
    }
  }


  onChange: any = () => { };
  onTouched: any = () => { };
  @Input()
  set list(list) {
    this._list.next(list);
  }

  set items(list) {
    this._items = list;
  }
  get items(): { id: number | string, display: string, checked: boolean, category: string, isMapped: boolean, disable?: boolean }[] {
    return this._items;
  }
  get value() {
    return this._value;
  }

  set value(val) {
    this._value = val;
  }

  get display() {
    return this._display;
  }
  @Input()
  set display(value) {
    this._display = value;
  }
  textCategorySearchPipe = new DropdownSearchPipe();
  constructor(private elemRef: ElementRef, private translate: TranslateService,
    private st: SimpleTimer, private localCacheService: LocalCacheService, config: NgbDropdownConfig) {
    this.pressEnterKey = this.keyDowns.pipe(filter((e: KeyboardEvent) => e.keyCode === KEY_CODE.enter));
    config.placement = 'bottom';
    config.autoClose = false;
    this.counter = 0;
  }
  ngOnInit() {
    this._list.subscribe((list) => {
      this.items = list;
      //   this.setItem(this.findItem(this.value));
    });

    if (!this.isAssetDetails && this.items.length == 1) {
      this.allOption = false;
    }

    this.pressEnterKey.pipe(filter(() => !this.isListHide)).subscribe(() => {
      const hightLightItem = this.listItems.find((elem) => elem.nativeElement.classList.contains(CSS_CLASS_NAMES.highLight));
      if (hightLightItem) {
        const item = JSON.parse(hightLightItem.nativeElement.getAttribute('data-dd-value'));
        this.setItem(item);
        this.onChange(item.id);
      }
    });

    this.pressEnterKey.subscribe((e) => {
      this.toggle();
    });

    this.keyDowns.pipe(filter((e) => e.keyCode === KEY_CODE.esc)).subscribe(() => {
      this.isListHide = true;
      this.focus();
    });
    this.keyDowns.pipe(filter((e) => ((e.keyCode === KEY_CODE.arrowDown || e.keyCode === KEY_CODE.arrowUp) && !this.isListHide))).subscribe((e) => {
      this.moveUpAndDown(e.keyCode);
    });

    if (this.multiSelection) {
      this.setMultiselection();
    } else {
      if (this.displayOnInit.length > 0) {
        this.setItem(this.displayOnInit[0]);
      }
    }
    this.filterCategoryList();
  }

  setMultiselection() {
    this.multiselectedItems = this.displayOnInit;
    if (this.isOmniSurveysTab && (this.displayOnInit.length == this.items.length) && this.multiSelection) {
      this.display = this.translate.instant("COMMON.ALL");
    } else if (this.displayOnInit.length === 1) {
      this.setItem(this.displayOnInit[0]);

    } else if (this.displayOnInit.length > 1) {
      this.value = null;
      if (this.multiselectedItems.length == this.items.length && this.multiSelection) {
        this.display = this.translate.instant("COMMON.ALL");
      }
      else if (this.isAssetDetails && this.displayOnInit.length == this.items.length && this.multiSelection) {
        this.display = this.translate.instant("COMMON.ALL");
      } else {
        this.display = this.multiSelection ? this.translate.instant("COMMON.MULTIPLE_SELECTION") :
          this.translate.instant("COMMON.MULTIPLE_SELECTED");
      }

    } else {
      this.value = '';
      this.display = this.isWaterUsage ? this.translate.instant('COMMON.SELECT_PORT_MEASUREMENT') : this.placeholder;
      // this.display = this.placeholder;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.depValue || changes.dependentValue) {
      if (this.depValue || this.dependentValue) {
        this.setItem('');
        this.value = '';
        this.display = this.isWaterUsage ? this.translate.instant('COMMON.SELECT_PORT_MEASUREMENT') : this.placeholder;
        this.multiselectedItems = [];
      }
    }
    if (changes.showUnmapped || (changes.viewFavorite && this.viewFavorite)) {
      if (this.multiSelection) {
        this.setMultiselection();
      }
    }

    if (this.displayOnInit.length > 0) {
      this.setMultiselection();
    } else {
      this.display = this.isWaterUsage ? this.translate.instant('COMMON.SELECT_PORT_MEASUREMENT') : this.placeholder;
    }
    if (changes.isSelectAllSelected) {
      this.onSelectAll({ target: { checked: changes.isSelectAllSelected.currentValue } });
    }
  }

  filterCategoryList() {
    this.categoryList = [];
    let id = 1;
    this.items.forEach(item => {
      if (item.display) {
        let str = item.display.split('|');
        let categoryItem = str[0].trim();
        let mappedInfo = str[str.length - 1].trim();
        if (!this.showUnmapped && mappedInfo !== '(unmapped)') {
          if (this.categoryList.findIndex(x => x.text === categoryItem) === -1) {
            this.categoryList.push({
              value: id,
              text: categoryItem
            });
            id++;
          }
        } else if (this.showUnmapped) {
          if (this.categoryList.findIndex(x => x.text === categoryItem) === -1) {
            this.categoryList.push({
              value: id,
              text: categoryItem
            });
            id++;
          }
        }
      }
    });
    this.categoryList.sort(function (a, b) {
      const x = a.text.toUpperCase();
      const y = b.text.toUpperCase();
      return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    });
  }

  onCategoryChange(category, event) {
    let checkedFlag = false;
    for (let i = 0; i < this.categoryList.length; i++) {
      if (this.categoryList[i].text == category.text) {
        if (category.checked == false) {
          this.categoryList[i].checked = true;
          checkedFlag = true;
          this.selectedCategory = this.categoryList[i].text;
        } else {
          this.categoryList[i].checked = false;
        }
      } else {
        this.categoryList[i].checked = false;
      }
    }
    if (checkedFlag == false) {
      this.selectedCategory = '';
    }
  }

  onKeyUp(searchText) {
    this.filterText.emit(searchText);
  }

  scrollToView(elem?: HTMLElement) {
    if (elem) {
      setTimeout(() => elem.scrollIntoView(), 0)
    } else {
      const selectedItem = this.listItems.find((item) => JSON.parse(item.nativeElement.getAttribute('data-dd-value'))['id'] === this.value);
      if (selectedItem) {
        setTimeout(() => selectedItem.nativeElement.scrollIntoView(), 0);
      }
    }
  }

  toggle() {
    if (this.showFilterBycategory) {
      this.dropdown.close();
    }
    this.isListHide = !this.isListHide;
    if (!this.isListHide) {
      if (this.selectedCategory !== '') {
        this.selectedCategory = '';
        this.categoryList.forEach(item => {
          item.checked = false;
        });
      }

      if (this.searchText !== '') {
        this.searchText = '';

        if (this.SearchType === 'server') {
          this.filterText.emit('');
        }
      } else {
        if (this.SearchType === 'server') {
          this.getSelectedItems.emit();
        }
      }
      if (this.SearchType === 'list') {
        this.getSelectedItems.emit();
      }
      if (this.showSearchBox) {
        setTimeout(() => this.filterInput.nativeElement.focus(), 0);
      }

      this.selectionLimitOverMsg = false;
      this.disableRecordsFlag = false;
      this.listItems.forEach((item) => {
        if (JSON.parse(item.nativeElement.getAttribute('data-dd-value'))['id'] === this.value) {
          this.addHightLightClass(item.nativeElement);
          if (!this.isfromMyDocuments) {
            if (this.scrolltoTop === undefined || this.scrolltoTop === true) {
              this.scrollToView(item.nativeElement);
            }
          }
        } else {
          this.removeHightLightClass(item.nativeElement);
        }
      });
      this.multiselectedItems = [];
      this.selectedCount = 0;
      this.items.forEach(item => {
        if (item.checked) {
          this.selectedCount++;
          this.multiselectedItems.push(item);
          if (!this.multiSelection) {
            this.setItem(item);
          }
        }
      });
      if (this.selectedCount === 0) {
        if (this.displayOnInit.length > 0) {

          if (!this.multiSelection) {
            this.selectedCount++;
            this.setItem(this.displayOnInit[0]);
          } else {
            if (this.displayOnInit.length > 0) {
              this.displayOnInit.forEach(initElement => {
                this.items.forEach(element => {
                  if (initElement.display === element.display) {
                    element.checked = true;
                    this.selectedCount++;
                    this.multiselectedItems.push(initElement);
                  }
                });
              });
            }
          }
        }
      }
    } else {
      this.st.delTimer('1Sec');
      this.st.unsubscribe('1Sec');
      this.counter = 0;
      this.selectionLimitOverMsg = false;
    }
    if (this.multiSelection) {
      this.getSelectedItems.emit(this.multiselectedItems);
    }
  }

  focus() {
    setTimeout(() => this.displayLabel.nativeElement.focus(), 0);
  }

  sortByKey(array, key) {
    return array.sort(function (a, b) {
      const x = a[key].toUpperCase();
      const y = b[key].toUpperCase();
      return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    });
  }

  onItemSelect(item, event) {

    if (event.target.checked && !this.multiSelection) {
      this.selectedCount = 0;
      this.items.forEach(element => {
        if (typeof this.value === 'string' && element.id === this.value) {
          element.checked = false;
        } else if (typeof this.value === 'number' && element.id === parseInt(this.value, 10)) {
          element.checked = false;
        }
      });
    }

    /**
     *** Code Explaination Started ***

     * @description Below code snippet executed when form validation is applied to the dropdown, code snippet helps in selecting the item from dropdown for both multiSelect and singleSelect
     */

    if (this.dropdownStatus) {                             // Check if the dropdownStatus is not null
      if (this.multiSelection) {                            // Condition satisfy when multiselection is TRUE [For multi select dropdown]
        event.target.checked = !event.target.checked      // Changes the state of check box, if TRUE then change to FALSE and vice-versa
      } else {                                              // Else code snippet is executed when the condition is FALSE
        this.items.forEach(element => {                   // Iterate over each item in items variable
          if (element.id === item.id) {                   // Condition satisfy only when the item id matches with the required one
            event.target.checked = true                   // if item found, checked status changes to TRUE
          } else {
            element.checked = false;                      // Else remaining all element checked status changes to FALSE
          }
        });
        this.isListHide = true;                           // Closes the dropdown after click of an element
      }
    }

    /**
     *** Code Explaination Ended ***
    */


    if (event.target.checked) {
      this.selectedCount++;

      if (item !== undefined) {
        this.onChange(item.id);
      } else {
        this.onChange('');
      }
      this.focus();
      if (this.multiSelection) {

        this.items.forEach(element => {
          if (element.id === item.id) {
            element.checked = true;
          }
        });
        let index = this.multiselectedItems.findIndex(x => x.display === item.display);
        // To validate few more conditions along with 'x.display === item.display' for AH Data Source Measuremnts
        let indexForAh = !this.multiSelection ? this.multiselectedItems.findIndex(x =>
          x.display === item.display
          && x.id.FromMeasuremntInstanceId === item.id.FromMeasuremntInstanceId
          && x.id.ToMeasuremntInstanceId === item.id.ToMeasuremntInstanceId
          && x.id.FromMeasurementInstance.ContextPointId === item.id.FromMeasurementInstance.ContextPointId
          && x.id.ToMeasurementInstance.ContextPointId === item.id.ToMeasurementInstance.ContextPointId
        ) : '';

        if (index === -1 || indexForAh === -1) {
          this.multiselectedItems.push(item);
        }

        if (!this.isSelectAllAvailable)
          this.isSelectAllSelected = false;

        if (!this.isSelectAllSelected) {
          this.itemSelected.emit(this.multiselectedItems);
          this.displayOnInit = this.multiselectedItems;
        }
      } else {
        this.setItem(item);
        this.itemSelected.emit(item.id);
        this.multiselectedItems = [];
        this.multiselectedItems.push(item)
        this.items.forEach(element => {
          if (typeof this.value === 'string' && element.id === this.value) {
            element.checked = true;
          } else if (typeof this.value === 'number' && element.id === parseInt(this.value, 10)) {
            element.checked = true;
          } else {
            element.checked = false;
          }
        });
      }

      // this.multiselectedItems.push(item);
    } else {
      this.selectedCount--;
      if (this.multiSelection) {
        this.items.forEach(element => {
          if (element.id === item.id) {
            element.checked = false;
          }
        });
        let index = this.multiselectedItems.findIndex(x => x.display === item.display);
        if (index > -1) {
          this.multiselectedItems.splice(index, 1);
        }
        this.itemSelected.emit(this.multiselectedItems);
        this.itemUnchecked.emit(item);
        this.displayOnInit = this.multiselectedItems;
      } else {
        this.items.forEach(element => {
          //    if (element.id === parseInt(this.value, 10)) {
          element.checked = false;
          //    }
        });
        this.setItem('');
        this.itemSelected.emit();
        this.multiselectedItems = [];
      }
      // this.itemSelected.emit();
    }

    if (this.multiSelection) {

      if (this.multiselectedItems.length === 1) {
        this.value = this.multiselectedItems[0].id;
        this.display = this.multiselectedItems[0].display;
        this.setItem(this.multiselectedItems[0]);

      } else if (this.multiselectedItems.length > 1) {
        this.value = null;
        if (this.multiselectedItems.length == this.items.length) {
          this.display = this.translate.instant("COMMON.ALL");
        } else if (this.isOmniSurveysTab && this.multiselectedItems.length < this.items.length) {
          this.display = this.translate.instant("OMNI.SURVEYS.SELECTED_VALUES", { value: this.multiselectedItems.length });
        } else {
          this.display = this.multiSelection ? this.translate.instant("COMMON.MULTIPLE_SELECTION") :
            this.translate.instant("COMMON.MULTIPLE_SELECTED");
        }

      } else {
        this.value = '';
        this.display = this.isWaterUsage ? this.translate.instant('COMMON.SELECT_PORT_MEASUREMENT') : this.placeholder;
        // this.display = this.placeholder;
        this.disableClearSelection = true;
      }
      // this.getSelectedItems.emit();
      if (this.multiselectedItems.length > this.checkedItemsLimit) {
        this.items.forEach(element => {
          if (typeof this.value === 'string' && element.id === this.value) {
            element.checked = false;
          } else if (typeof this.value === 'number' && element.id === parseInt(this.value, 10)) {
            element.checked = false;
          }
        });

      } else {
        this.st.delTimer('1Sec');
        this.st.unsubscribe('1Sec');
        this.counter = 0;
        this.selectionLimitOverMsg = false;
      }
    }
    // this.items = this.sortByKey(this.items, 'display');

    /**
     *** Code Explaination Started ***

     * Below code snippet execute when form validation is applied to the dropdown and when multiselection is set to false
     */

    if (this.dropdownStatus) {             // Check if the dropdownStatus is not null
      if (!this.multiSelection) {           // Condition satisfy when multiselection is FALSE [For sigle select dropdown]
        this.onDocClickValidation()       // Calls the emits function for passing data back to parent component on close of dropdown
      }
    }

    /**
     *** Code Explaination Ended ***
    */
  }

  callback() {
    this.counter++;
    if (this.counter === 60) {
      this.st.delTimer('1Sec');
      this.st.unsubscribe('1Sec');
      this.counter = 0;
      this.selectionLimitOverMsg = false;
    }
  }

  checkBoxClick(event) {
    if (this.multiselectedItems.length >= this.checkedItemsLimit && this.items.length > this.checkedItemsLimit) {
      this.selectionLimitOverMsg = true;
      this.disableRecordsFlag = true;
      this.counter = 0;

      this.newTimer = this.st.newTimer('1Sec', 1);
      if (this.newTimer) {
        this.timerId = this.st.subscribe('1Sec', () => this.callback());
      }
    } else {
      this.st.delTimer('1Sec');
      this.st.unsubscribe('1Sec');
      this.counter = 0;
      this.selectionLimitOverMsg = false;
      this.disableRecordsFlag = false;
    }
  }

  onClear() {
    let checkedItems = 0;
    this.items.forEach(item => {
      if (item.checked === true) {
        checkedItems++;
      }
    });

    if (checkedItems > 0) {
      this.items.forEach(item => {
        item.checked = false;
      });

      if (this.searchText !== '') {
        this.searchText = '';

        if (this.SearchType === 'server') {
          this.filterText.emit(this.searchText);
        }
      }
      // this.selectedCategory = {value:'', text:'', checked:false};
      for (let i = 0; i < this.categoryList.length; i++) {
        this.categoryList[i].checked = false;
      }
      this.disableRecordsFlag = false;
      this.selectionLimitOverMsg = false;
      this.st.delTimer('1Sec');
      this.st.unsubscribe('1Sec');
      this.counter = 0;
      this.selectedCategory = '';
      this.multiselectedItems = [];
      this.setItem('');
      this.itemSelected.emit();
      this.getSelectedItems.emit([]);
      // this.categorySelected.emit({value:'', text:'', checked:false});
      if (this.isfromMyDocuments) {
        this.items = this.items
      }
      else {
        this.items = this.sortByKey(this.items, 'display');
      }

    }
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  findItem(value) {
    return this.items.find((item) => +item.id === +value);
  }

  writeValue(value) {
    const item = this.findItem(value)
    this.value = value;
    this.display = item ? item.display : '';
  }

  setItem(item) {
    if (item) {
      if (item.id) {
        this.value = item.id;
      }

      this.display = item.display;
      this.disableClearSelection = false;
    } else {
      this.value = '';
      this.display = this.isWaterUsage ? this.translate.instant('COMMON.SELECT_PORT_MEASUREMENT') : this.placeholder;
      this.disableClearSelection = true;
    }
  }

  onKeyPress(e: KeyboardEvent) {
    if (e.keyCode === KEY_CODE.enter) {
      this.focus();
      return false;
    }
  }

  addHightLightClass(elem: HTMLElement) {
    elem.classList.add(CSS_CLASS_NAMES.highLight)
  }

  removeHightLightClass(elem: HTMLElement) {
    elem.classList.remove(CSS_CLASS_NAMES.highLight);
  }

  moveUpAndDown(key: number) {
    const selectedItem = this.listItems.find((li) => li.nativeElement.classList.contains(CSS_CLASS_NAMES.highLight));
    if (selectedItem) {
      let hightLightedItem: HTMLElement;
      if (key === KEY_CODE.arrowUp) {
        //check for first element
        if (selectedItem !== this.listItems.first) {
          hightLightedItem = selectedItem.nativeElement.previousSibling;
        }
      } else if (key === KEY_CODE.arrowDown) {
        //check for last element
        if (selectedItem !== this.listItems.last) {
          hightLightedItem = selectedItem.nativeElement.nextSibling;
        }
      }
      if (hightLightedItem) {
        this.clearHlightClass();
        this.removeHightLightClass(selectedItem.nativeElement);
        this.addHightLightClass(hightLightedItem);
        this.scrollToView(hightLightedItem);
      }
    } else {
      let highLightedItem: ElementRef;
      if (key === KEY_CODE.arrowUp) {
        highLightedItem = this.listItems.last;
      }
      else if (key === KEY_CODE.arrowDown) {
        highLightedItem = this.listItems.first;
      }
      if (highLightedItem) {
        this.addHightLightClass(highLightedItem.nativeElement);
        this.scrollToView(highLightedItem.nativeElement);
      }
    }
  }

  isSelected(item: { id: number, display: string }) {
    return +item.id === +this.value;
  }

  stringify(item) {
    return JSON.stringify(item);
  }

  onHover(event: MouseEvent) {
    this.clearHlightClass();
    const target = event.target as HTMLElement;
    const classNames = target.classList;

    if (event.type === 'mouseover') {
      if (classNames.contains('form-check-label') && (classNames.contains('radio-items') || classNames.contains('checkbox-items'))) {
        target.parentElement.classList.add(CSS_CLASS_NAMES.highLight);
      } else {
        target.classList.add(CSS_CLASS_NAMES.highLight);
      }
    } else {
      if (classNames.contains('form-check-label') && (classNames.contains('radio-items') || classNames.contains('checkbox-items'))) {
        target.parentElement.classList.remove(CSS_CLASS_NAMES.highLight);
      } else {
        target.classList.add(CSS_CLASS_NAMES.highLight);
      }
    }
  }


  clearHlightClass() {
    this.listItems.forEach((item) => {
      this.removeHightLightClass(item.nativeElement);
    })
  }

  private isSelectAllChecked(ele) {
    if (this.items.filter(item => item.checked).length === Array.from(ele.closest('.dd-list-container').querySelectorAll('.dd-list-item')).length) {
      this.isSelectAllSelected = true;
    } else {
      this.isSelectAllSelected = false;
    }

    if (this.isOmniSurveysTab) {
      this.multiselectText = this.isSelectAllSelected ? 'Unselect All' : 'Select All';
    }
    return this.isSelectAllSelected;
  }
  private onSelectAll($event) {
    this.unSelectAll.emit($event.target.checked);
    this.isSelectAllSelected ? this.isSelectAllSelected = false : this.isSelectAllSelected = true;
    const selectedItems = this.textCategorySearchPipe.transform(this.items, this.searchText, this.selectedCategory, true);
    this.items.forEach(item => selectedItems.find(it => it.display === item.display) ? this.onItemSelect(item, { target: { checked: $event.target.checked } }) : null);
    if (this.isSelectAllSelected && this.multiselectedItems.length > 0 && this.items.length > 0) {
      if (this.forManageSampleSet) {
        this.itemSelected.emit(this.items);
        this.displayOnInit = this.items;
      } else {
        this.itemSelected.emit(this.multiselectedItems);
        this.displayOnInit = this.multiselectedItems;
      }
    }
    if (this.multiselectedItems.length == this.items.length && this.multiSelection) {


      this.display = this.translate.instant("COMMON.ALL");
    }

  }


  onDropdownClick() {
    if (this.scrolltoTop === undefined || this.scrolltoTop === true) {
      this.scroll.nativeElement.scrollTop = 0;
    }

    /**
     *** Code Explaination Started ***

     * @description Below code snippet is for handling the dropdown status [weather the dropdown is open or close] and emmit the selected item from dropdown back to parent component when user close the dropdown by clicking on dropdown itself
     * @returns {object} An object containing the following properties: LENGTH (number of selected items), selected ITEMS, DROPDOWN-ID, and DROPDOWN-STATUS.
     */

    if (this.dropdownStatus) {                                                // Check if the dropdownStatus is not null
      if (this.dropdownStatus[this.componentId]) {                             // Condition is satisfied when the dropdown is open [ TRUE ]
        this.dropdownStatus[this.componentId] = false                        // Changing the dropdownStatus value to FALSE [dropdown closed]
        this.toggleEvent.emit({
          "length": this.multiselectedItems.length,
          "selectedItems": this.multiselectedItems, "id": this.componentId,
          "dropdownStatus": this.dropdownStatus
        });                           // Emitting the LENGTH, ITEM-SELECT, DROPDOWN-ID, DROPDOWN-STATUS back to parent component
      } else {                                                                 // Else block executes when the dropdown is closed [ FALSE ]
        setTimeout(() => {
          this.dropdownStatus[this.componentId] = true                       // Changing the dropdownStatus value to TRUE [dropdown opened]
          this.toggleEvent.emit({
            "length": null, "selectedItems": null,
            "id": null, "dropdownStatus": this.dropdownStatus
          });               // Emitting null for LENGTH, ITEM-SELECT, DROPDOWN-ID, DROPDOWN-STATUS. Else block code is for handling dropdownStatus
        }, 200); // On execution of else block code, onDocClickValidation is also getting triggered, to handle that setTimeout is applied so that dropdownStatus updates after the execution of onDocClickValidation function
      }
    }

    /**
     *** Code Explaination Ended ***
    */
  }

  /**
   *** Code Explaination Started ***

   * @description Below code snippet is for handling the dropdown status [weather the dropdown is open or close], form validation and emmit the selected item from dropdown back to parent component when user close the dropdown by clicking on DOM
   * @returns {object} An object containing the following properties: LENGTH (number of selected items), selected ITEMS, DROPDOWN-ID, and DROPDOWN-STATUS.
   */

  onDocClickValidation() {
    if (this.dropdownStatus) {                                                // Check if the dropdownStatus is not null
      if (this.dropdownStatus[this.componentId]) {                             // Condition is satisfied when the dropdown is open [ TRUE ]
        this.dropdownStatus[this.componentId] = false                        // Changing the dropdownStatus value to FALSE [dropdown closed]
        this.toggleEvent.emit({
          "length": this.multiselectedItems.length,
          "selectedItems": this.multiselectedItems, "id": this.componentId,
          "dropdownStatus": this.dropdownStatus
        });                           // Emitting the LENGTH, ITEM-SELECT, DROPDOWN-ID, DROPDOWN-STATUS back to parent component
      }
    }
  }

  /**
   *** Code Explaination Ended ***
  */

  /**
  * @description generates the content for the tooltip based on the selected items.
  * @returns {string} The content for the tooltip.
  */
  generateTooltipContent(): string {
    if (Array.isArray(this.multiselectedItems) && this.multiselectedItems.length > 0) {
      const selectedValues = Array.from(new Set(this.multiselectedItems.map(item => item.display))).join(', ');
      return selectedValues;
    }
  }
}
