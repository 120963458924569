import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal, NgbTabset } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs/Subscription';
import { LocalCacheService } from '../../../../core/local-cache/local-cache.service';
import { InventoryMultipleDataSources, InventoryTableColumns } from '../../../../shared-ssd/_enum/constants';
import { InventoryParameters, InventoryTargetUsage } from '../../../../shared-ssd/_enum/inventoryParameters.enum';
import { InventorySelection, InventoryUsage, InventoryWidgetConfiguration } from '../../../../shared-ssd/_models/InventoryUsage';
import { ServiceType } from '../../../_enum/constants';
import { SharedService } from '../../../services/shared-services.service';
import { ErrorHandling } from '../../error-handling/error-handling';
import { XdComponentState } from '../../xd-container/xd-component';
import { ComponentState } from '../../xd-container/xd-component-state';


@Component({
  selector: 'nalco-inventory-list',
  templateUrl: './inventory-list.component.html',
  styleUrls: ['./inventory-list.component.scss'],
  providers: [
    NgbTabset
  ]
})
export class InventoryListComponent extends XdComponentState implements OnInit {

  //TODO - map all of type 'any' to correct models
  inventoryDetailsForm: UntypedFormGroup;
  columnsList = JSON.parse(JSON.stringify(InventoryTableColumns));
  multipleDataSourceList = JSON.parse(JSON.stringify(InventoryMultipleDataSources));
  defaultColumnsChecked?: any[] = [];
  @Input() contextPointId: number;

  /* If Columns Tab should be shown */
  @Input() isColumnTabPresent?: boolean = false;

  /* List of all columns to be shown if Columns Tab is present*/
  @Input() columns?: any = this.columnsList;

  /* List of all default columns to be checked if Columns Tab is present*/
  @Input() dataForColumnsTab?: any[] = [];

  /* If Tanks Tab should be shown */
  @Input() isTanksTabPresent?: boolean = true;

  /* Tanks data to be shown if Tanks Tab is present*/
  @Input() dataForTanksTab?: any;

  @Input() duration?: number;
  @Input() ssdCreateNewEditView? = false;
  @Input() fromSessionStorage?: boolean = false;
  @Input() serviceTypeId: number;
  @Input() groupCpId?: number;
  @Output() addCancelInv = new EventEmitter<any>();
  customizeColumns: any[] = [];
  activeTab = 'columns';
  allTanksData: InventoryUsage[] = [];
  startDate: string;
  endDate: string;
  targetUsageDropDown: any;
  errorInfo: any;
  errorHandling = new ErrorHandling(this.modalService, this.translate);
  masterTanksData: any = [];
  private subscriptions: Subscription[] = [];
  isColsChanged = false;
  isShowMultipleDataources = false;
  unhideTargetUsagefromTanks = false;

  constructor(private fb: UntypedFormBuilder,
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private sharedService: SharedService,
    private translate: TranslateService,
    private router: Router,
    private localCacheService: LocalCacheService,
    ) {
    super();
    this.inventoryDetailsForm = this.fb.group({
      checkUncheckAll: '',
      formDetails: this.fb.array([])
    })
  }

  get formRowArray(): UntypedFormArray {
    return this.inventoryDetailsForm.get('formDetails') as UntypedFormArray;
  }

  ngOnInit() {
    this.setState(ComponentState.loading);
    this.getAssetData();
  }

  myParentInstance(): InventoryListComponent{
    return this;
  }

  addNewRow(): void {
    this.formRowArray.push(this.createFormData());
  }

  createFormData(): UntypedFormGroup {
    return this.fb.group({
      tankSelected: '',
      dataSource: [{ value: false, disabled: true }],
      slugFeed: [{ value: '', disabled: true }],
      noOfSlugs: [{ value: '', disabled: true }],
      targetUsageType: [{ value: '', disabled: true }],
      targetUsageValue: [{ value: '', disabled: true }],
    })
  }

  getAssetData() {
    this.setState(ComponentState.loading);
    this.subscriptions.push(this.sharedService.getAssetsByAssetType(this.contextPointId, 'AssetConfiguration', this.groupCpId).subscribe(response => {
      if (response && response.length > 0) {
        response.forEach(data => {
          if (data.AssetConfig && data.AssetConfig.Product && data.AssetConfig.Product.Identifiers !== null && data.AssetConfig.Product.Identifiers.length > 0) {
            const productNameIdentifier = data.AssetConfig.Product.Identifiers.find((identifier) => identifier.Domain == 'ProductName');
            const lastElement = this.multipleDataSourceList.length > 0 ? this.multipleDataSourceList.slice(-1) : [];
            let filteredAssetDataSourceTypes = [];
            filteredAssetDataSourceTypes = (data.AssetDataSourceTypes && data.AssetDataSourceTypes.length <= 1) ?
              this.multipleDataSourceList.filter((secondObj: any) => data.AssetDataSourceTypes.some((firstObj: any) => firstObj === secondObj.value)) : lastElement
            if (productNameIdentifier) {
              const obj = {
                'Aliases': data.AssetConfig.Aliases,
                'AssetDataSourceTypes': filteredAssetDataSourceTypes,
                'DataSourceTypes': data.AssetDataSourceTypes,
                'ContextPointId': data.ContextPointId,
                'ProductNameValue': productNameIdentifier.Value
              }
              this.masterTanksData.push(obj);
            }
          }
        });
        this.masterTanksData.map(x => this.addNewRow());
        this.targetUsageDropDown = JSON.parse(JSON.stringify(InventoryTargetUsage));
        this.updateDefaultColumns();
        this.updateDefaultTanks();
        this.setState(ComponentState.ready);
      } else {
        this.setState(ComponentState.empty);
      }
    }, error => {
      this.errorInfo = error;
      if (500 <= error.status && error.status < 600) {
        return;
      } else if (error.status === 417) {
        this.router.navigate(['/access-denied']);
      } else if (error.status === 404) {
        this.router.navigate(['/**']);
      } else {
        this.errorHandling.showErrorPopup(error);
      }
      this.setState(ComponentState.error);
    }));
  }


  // update default columns when the pop-up opens
  updateDefaultColumns() {
    const cacheCustomization = JSON.parse(this.localCacheService.fetchData('inventoryColumns_' + ServiceType[this.serviceTypeId]));
    if (cacheCustomization != null && cacheCustomization !== undefined && cacheCustomization !== ''
      && this.fromSessionStorage === true) {
      this.getColumnsChecked(cacheCustomization);
    } else if (this.isColumnTabPresent && this.dataForColumnsTab && this.dataForColumnsTab.length > 0
      && this.columns && this.columns.length > 0) {
      this.getColumnsChecked(this.dataForColumnsTab);
    } else if (this.columns && this.columns.length > 0) {
      this.columns.forEach(item => {
        if (item.defaultActive) {
          this.defaultColumnsChecked.push(item.title);
        }
      });
    }
  }

  getColumnsChecked(data) {
    this.columns.forEach(item => {
      const index = data.findIndex(val => val == item.title)
      if (index > -1) {
        item.defaultActive = true;
        this.defaultColumnsChecked.push(item.title);
      } else {
        item.defaultActive = false;
      }
    })
  }

  // validate master tanks data with the saved tanks data for existing views
  selUnselAll() {
    let co = 0;
    this.masterTanksData.forEach(element => {
      const temp = this.dataForTanksTab.InventorySelections.filter(item => item.ContextPointId === element.ContextPointId);
      if (temp.length > 0) {
        co++
      }
    });
    return (co === this.masterTanksData.length);
  }

  updateDefaultTanks() {
    const arrControls = this.getControls();
    if (this.isTanksTabPresent && this.dataForTanksTab) {
      const selUnselAll = this.selUnselAll();
      if (selUnselAll)
        this.inventoryDetailsForm.controls['checkUncheckAll'].patchValue(true);
      const invSel = this.dataForTanksTab.InventorySelections;
      this.masterTanksData.forEach((mtd, i) => {
        const index = invSel.findIndex(x => x.ContextPointId == mtd.ContextPointId);
        const multipleDataSource = invSel[index] ? invSel[index].ShowMultipleDatasources : null
        this.isShowMultipleDataources = ((multipleDataSource == null && mtd.DataSourceTypes.length > 1) || multipleDataSource == true) ? true : false;
        if (index > -1) {
          mtd['isSelected'] = true;
          const tankData = {
            tankSelected: true,
            slugFeed: invSel[index].IsSlugFeed,
            dataSource: this.isShowMultipleDataources,
            noOfSlugs: invSel[index].NumberOfSlugs,
            targetUsageType: invSel[index].TargetUsageTypeId,
            targetUsageValue: invSel[index].TargetUsage,
          };
          arrControls[i].enable();
          if (invSel[index].IsSlugFeed === '' || invSel[index].IsSlugFeed === false || invSel[index].IsSlugFeed === null) {
            arrControls[i].get('noOfSlugs').disable();
          }
          if (invSel[index].TargetUsageTypeId === null) {
            arrControls[i].get('targetUsageValue').disable();
          }
          arrControls[i].patchValue(tankData);
        }
      })
    } else {
      this.inventoryDetailsForm.controls['checkUncheckAll'].patchValue(true);
      this.checkUncheckAll(true);
      if (this.ssdCreateNewEditView)
        this.inventoryDetailsForm.markAsDirty();
    }
  }

  // onTabChange(event) {
  // }

  onChangeItemInPopup(item, e) {
    const selectedTanksData = this.getCheckedItemsList();
    if (selectedTanksData && selectedTanksData.length > 0)
      this.isColsChanged = true;
    const index = this.columns.findIndex(x => x.title === item.title);
    if (e.target.checked) {
      this.defaultColumnsChecked.push(item.title);
      this.columns[index].defaultActive = true;
    } else {
      const i = this.defaultColumnsChecked.findIndex(x => x === item.title);
      this.defaultColumnsChecked.splice(i, 1);
      this.columns[index].defaultActive = false;
    }
  }

  getControls() {
    return (this.inventoryDetailsForm.get('formDetails') as UntypedFormArray).controls;
  }

  tankChecked(e, index, item?) {
    if (item) {
      item.isSelected = e;
    }
    const arrControls = this.getControls();
    if (e == true) {
      arrControls[index].get('slugFeed').enable();
      arrControls[index].get('targetUsageType').enable();
      arrControls[index].get('dataSource').enable();
      if (arrControls[index].get('dataSource').value === null) {
        arrControls[index].get('dataSource').setValue(false)
      }
    } else {
      arrControls[index].reset();
      arrControls[index].disable();
      arrControls[index].get('tankSelected').enable();
      const selectedTanksData = this.getCheckedItemsList();
      /* If no. of checked tanks is 0, then 'Add' button should be disabled irrespective of whether
      columns tab is changed or not */
      if (selectedTanksData && selectedTanksData.length > 0)
        this.inventoryDetailsForm.markAsDirty();
      else {
        this.inventoryDetailsForm.markAsPristine();
        this.isColsChanged = false;
      }
      this.inventoryDetailsForm.get('checkUncheckAll').setValue('');
    }
  }

  slugChecked(e, index) {
    const arrControls = this.getControls();
    if (e.target.checked) {
      arrControls[index].get('noOfSlugs').enable();
    } else {
      arrControls[index].get('noOfSlugs').disable();
      arrControls[index].get('noOfSlugs').setValue('');
    }
  }

  multipleDataSourceChecked(event, index) {
    const arrControls = this.getControls();
    if (event.target.checked) {
      arrControls[index].get('dataSource').enable();
    } else {
      // arrControls[index].get('dataSource').disable();
      arrControls[index].get('dataSource').setValue(false);
    }
  }

  onTarUsageChange(e, index) {
    const arrControls = this.getControls();
    if (e.target.value !== "") {
      arrControls[index].get('targetUsageValue').enable();
    }
  }

  getEventTargetChecked(e:Event){
    const target = e.target as HTMLInputElement;
    return target.checked;
  }

  checkUncheckAll(e:boolean) {
    const arrControls = this.getControls();
    this.masterTanksData.map((item, i) => {
      item['isSelected'] = e;
      arrControls[i].get('tankSelected').patchValue(e);
      this.tankChecked(e, i);
    });
    if (e === false) {
      this.inventoryDetailsForm.markAsPristine();
      this.isColsChanged = false;
    }
  }

  getCheckedItemsList() {
    const checkedTanksList = [];
    this.masterTanksData.forEach((item, i) => {
      if (item['isSelected'] == true) {
        item['index'] = i;
        checkedTanksList.push(item);
      }
    });
    return checkedTanksList;
  }

  getColumnsTot() {
    let count = 0;
    this.defaultColumnsChecked.forEach(item => {
      const obj = this.columns.find(o => o.title == item);
      if (obj) {
        count += parseInt(InventoryParameters[obj.field]);
      }
    })
    return count;
  }

  getPayloadforInventory() {
    const invSelection: InventorySelection[] = []
    const selectedTanksData = this.getCheckedItemsList();
    const colsTot = this.getColumnsTot();
    const arrControls = this.getControls();
    selectedTanksData.forEach(item => {
      const temp = arrControls[item.index].get('targetUsageType').value;
      invSelection.push({
        'ContextPointId': item.ContextPointId,
        'IsSlugFeed': arrControls[item.index].get('slugFeed').value,
        'ShowMultipleDatasources': arrControls[item.index].get('dataSource').value,
        'NumberOfSlugs': arrControls[item.index].get('noOfSlugs').value,
        'TargetUsageTypeId': ((temp || temp == 0) && temp !== "") ? parseInt(arrControls[item.index].get('targetUsageType').value) : null,
        'TargetUsage': arrControls[item.index].get('targetUsageValue').value
      })
    });
    const obj: InventoryWidgetConfiguration = {
      'InventoryParameterSelection': InventoryParameters.AllParameters, //colsTot,
      // 'ShowAllInventory': colsTot === InventoryParameters.AllParameters ? true : false,
      'ShowAllInventory': invSelection && invSelection.length > 0 ? false : true,
      'InventorySelections': invSelection
    }
    return obj;
  }

  addInventoryData() {
    const params = this.getPayloadforInventory();
    const isCallNeeded = this.inventoryDetailsForm.dirty ? true : false;
    this.addCancelInv.emit({ cancelAction: false, columnsSelected: this.defaultColumnsChecked, selectedInventoryList: params, isCallNeeded: isCallNeeded });
  }

  dimissModal() {
    this.addCancelInv.emit({ cancelAction: true, selectedInventoryList: [] });
    this.activeModal.close();
  }

  onKeyPress(event) {
    if (event.charCode >= 48 && event.charCode <= 57) {
      if (event.target.value.length < 9)
        return true
    }
    return false;
  }

  keyPress(event) {
    const regex = /^\d*\.?\d{0,1}$/;
    if ((event.charCode >= 48 && event.charCode <= 57) || event.charCode == 46) {
      if (event.target.value.length < 9){
        if (regex.test(event.target.value) && event.target.value.length <= 9) {
          return true;
        }
      }
    }
    return false;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
